import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import { parseDateToString } from '@/auth/utils';
import contactStoreModule from '../contactStoreModule';

export default function useContactGroupModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contact';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contactStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      gender: itemLocal.value.genderObject ? itemLocal.value.genderObject.id : 'other',
      phone: itemLocal.value.phone,
      zaloPhone: itemLocal.value.zaloPhone,
      note: itemLocal.value.note,
      email: itemLocal.value.email,
      birthday: parseDateToString(itemLocal.value.birthday),
      address: itemLocal.value.address,
      provinceId: itemLocal.value.province ? itemLocal.value.province.id : null,
      districtId: itemLocal.value.district ? itemLocal.value.district.id : null,
      wardId: itemLocal.value.ward ? itemLocal.value.ward.id : null,
      tags: itemLocal.value.tagsArray.join(","),
      refId: itemLocal.value.refId ?? '',
      firstCustomData: itemLocal.value.firstCustomData ?? '',
      secondCustomData: itemLocal.value.secondCustomData ?? '',
      thirdCustomData: itemLocal.value.thirdCustomData ?? '',
    };

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('contact/updateItem', { data, id: itemLocal.value.id })
        .then(() => {
          isSubmitting.value = false;
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('contact/createItem', data)
        .then(response => { emit('on-item-created', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}

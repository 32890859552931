<template>
  <b-modal
    id="modal-contact"
    ref="refModal"
    title="Khách hàng"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Họ tên <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Nguyễn Văn A"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <select-gender
              v-model="itemLocal.genderObject"
              :default-value="itemLocal.genderObject"
              label="Giới tính"
            />
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group
                label="'Số điện thoại'"
              >
                <template
                  v-slot:label
                >
                  Số điện thoại <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0868987355"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="zaloPhone"
              rules=""
            >
              <b-form-group
                label="Số điện thoại Zalo"
              >
                <template
                  v-slot:label
                >
                  Số điện thoại Zalo
                </template>
                <b-form-input
                  id="zaloPhone"
                  v-model="itemLocal.zaloPhone"
                  placeholder="0868987355"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="email"
            >
              <b-form-group>
                <template
                  v-slot:label
                >
                  Email
                </template>
                <b-form-input
                  id="email"
                  v-model="itemLocal.email"
                  placeholder="example@email.com"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <select-date
              v-model="itemLocal.birthday"
              :default-value="itemLocal.birthday"
              label="Ngày sinh"
            />
          </b-col>
          <b-col md="6">
            <select-province
              v-model="itemLocal.province"
              :default-value="itemLocal.province"
            />
          </b-col>
          <b-col md="6">
            <select-district
              v-model="itemLocal.district"
              :default-value="itemLocal.district"
              :province="itemLocal.province"
            />
          </b-col>
          <b-col md="6">
            <select-ward
              v-model="itemLocal.ward"
              :district="itemLocal.district"
              :default-value="itemLocal.ward"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Địa chỉ chi tiết"
            >
              <b-form-input
                id="address"
                v-model="itemLocal.address"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="refId"
              rules=""
            >
              <b-form-group
                label="'Mã tham chiếu'"
              >
                <template
                  v-slot:label
                >
                  Mã tham chiếu
                </template>
                <b-form-input
                  id="refId"
                  v-model="itemLocal.refId"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="firstCustomData"
              rules=""
            >
              <b-form-group
                label="'Thông tin khác (1)'"
              >
                <template
                  v-slot:label
                >
                  Thông tin khác (1)
                </template>
                <b-form-input
                  id="firstCustomData"
                  v-model="itemLocal.firstCustomData"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="secondCustomData"
              rules=""
            >
              <b-form-group
                label="'Thông tin khác (2)'"
              >
                <template
                  v-slot:label
                >
                  Thông tin khác (2)
                </template>
                <b-form-input
                  id="secondCustomData"
                  v-model="itemLocal.secondCustomData"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="thirdCustomData"
              rules=""
            >
              <b-form-group
                label="'Thông tin khác (3)'"
              >
                <template
                  v-slot:label
                >
                  Thông tin khác (3)
                </template>
                <b-form-input
                  id="thirdCustomData"
                  v-model="itemLocal.thirdCustomData"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          
          
        </b-row>
        <b-row>
          <b-col>

            <validation-provider
              #default="validationContext"
              name="tags"
              rules=""
            >
              <b-form-group
                label="Gán nhãn"
              >
                <template
                  v-slot:label
                >
                  Gán nhãn
                </template>
                <b-form-tags
                  v-model="itemLocal.tagsArray"
                  class="mb-2"
                  placeholder="Thêm tag bằng cách nhập xong nhấn Enter"
                  remove-on-delete
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Ghi chú"
            >
              <b-form-textarea
                v-model="itemLocal.note"
                rows="6"
              />
            </b-form-group>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormTags,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectGender from '@/views/components/SelectGender.vue';
import useContactModal from './useContactModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormTags,
    BFormTextarea,
    SelectDate,
    SelectProvince,
    SelectDistrict,
    SelectWard,
    SelectGender,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
    } = useContactModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
./useContactModal
